.fallback-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

// loader
.bars {
  width: 50.4px;
  height: 67.2px;
  --c: linear-gradient(#25af9b 0 0);
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  background-size: 10.1px 50%;
  background-repeat: no-repeat;
  animation: bars-7s9ul0md 0.8s infinite linear alternate;
}

@keyframes bars-7s9ul0md {
  20% {
    background-size: 10.1px 20%, 10.1px 50%, 10.1px 50%;
  }

  40% {
    background-size: 10.1px 100%, 10.1px 20%, 10.1px 50%;
  }

  60% {
    background-size: 10.1px 50%, 10.1px 100%, 10.1px 20%;
  }

  80% {
    background-size: 10.1px 50%, 10.1px 50%, 10.1px 100%;
  }
}

.ad_loader_wrapper {
  position: relative;
}
.home_ad_loader_wrapper{
  position: relative;
}

.footer_ad_wrapper{
  height: 250px;
}

.ad_inner_placeholder {
  background-color: rgba(122, 220, 204, 0.8);
  position: absolute;
  max-width: 997px !important;
  margin: 0 auto;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  opacity: 0.7;
  left: 50%;
  transform: translateX(-50%);
  width: 97%;
  // z-index: -1;
  filter: opacity(0.5);
  svg {
    width: 100px;
    height: 60px;
  }
  @media (max-width: 500px) {
    svg {
      height: 45px;
    }
  }
}

.vid_ad_inner_placeholder {
  background-color: rgba(122, 220, 204, 0.8);
  max-width: 440px !important;
  margin: 0 auto;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  opacity: 0.7;
  width: 100%;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  filter: opacity(0.5);
  svg {
    width: 100px;
    height: 60px;
  }

  @media (max-width: 500px) {
    height: 170px;
  }
}

.vm-vid {
  margin-top: -250px !important;

  @media (max-width: 500px) {
    margin-top: -170px !important;
  }
}
.home-baner-ads {
  @media (max-width: 500px) {
    height: 203px;
  }
}
.home-vm-vid {
  .vm-vid {
    @media (max-width: 500px) {
      margin-top: -203px !important;
    }
  }

}
.loader-container{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  .fallback-spinner{
    height: 100%;
  }
}