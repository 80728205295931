* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html{
  /* overflow-y: hidden; */
}
body {
  max-width: 100vw;
  /* overflow-x: hidden; */
  font-family: 'Poppins', sans-serif;
  font-display: swap;
}

.google-auto-placed {
  display: none !important;
}

/* image overlay */
/* styles.css or any other CSS file */
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
 }
 
 .image-container img {
  display: block;
  width: 100%;
  height: auto;
 }
 
 .image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Adjust the color and opacity as needed */
  transition: opacity 0.3s ease;
 }
 
 .image-container:hover::after {
  opacity: 0;
 }